import React from "react";
import home from "../assets/images/home.jpg";
import image1 from "../assets/images/image1.jpg";
import image2 from "../assets/images/image2.jpg";
import image3 from "../assets/images/image3.jpg";
import image4 from "../assets/images/image4.jpg";
import image5 from "../assets/images/image5.jpg";
import image6 from "../assets/images/image6.jpg";
import tomato from "../assets/images/tomato.jpg";
import banana from "../assets/images/banana.jpg";
import nursery from "../assets/images/nursery.jpg";
import greenpepper from "../assets/images/pepper.jpg";

function Home() {
  return (
    <div>
      <div className="relative">
        <div
          className="hero bg-cover bg-center text-white py-24"
          style={{
            backgroundImage: `url(${nursery})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40"></div>

          <div className="container mx-auto text-center relative z-10">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Welcome to Posiye Gardens
            </h1>
            <p className="text-lg md:text-xl mb-8">
            Cultivating Growth, One Plant at a Time.
            </p>
            <button className="bg-black bg-opacity-40 text-slate-100 font-bold py-2 px-4 rounded-lg">
              Get to know us
            </button>
          </div>
        </div>
      </div>

      <div className="home-content">
        <div
          className="flex flex-col sm:flex-row items-center mx-auto mt-14"
          style={{ width: "90%" }}
        >
          <div className="w-full sm:w-1/2">
            <h2
              className="font-[500] text-xl sm:text-3xl sm:w-2/3"
              style={{
                color: "#0e314c",
                lineHeight: "1.3",
              }}
            >
              Embrace the beauty of Posiye Gardens
            </h2>
            <p className="text-green-800 mb-2">
              Where Every Seedling Holds a Promise
            </p>
            <p
              className="my-4 text-sm sm:text-md  sm:w-3/4"
              style={{ color: "#6084a4" }}
            >
              Step into a world where nature's finest masterpieces come to life.
              At Posiye Gardens, we're not just passionate about cultivating
              beauty; we're artists of the earth. With an unwavering dedication
              to excellence, sustainability, and the enduring grace of trees, we
              extend a heartfelt invitation for you to embark on an
              extraordinary journey through our lush, green wonderland. Whether
              you're a seasoned gardener or a budding enthusiast, our
              meticulously curated selection of trees, nurtured with boundless
              care, eagerly anticipates your arrival. Immerse yourself in our
              thriving oasis, and let the enchantment of nature flourish in
              every corner of your life.
            </p>
            <a
              href="/about"
              className="my-4 hover:bg-green-800 text-white text-xs sm:text-sm font-[600] py-3 px-6 rounded flex items-center shadow-lg shadow-slate-300 w-[40%]"
              style={{ background: "#106E39" }}>
              EXPLORE MORE
            </a>
          </div>
          <div className="mt-4 sm:mt-0 ml-0 sm:w-1/2">
            <img className="shadow-lg" src={home} alt="" />
          </div>
        </div>
        <div
          id="services"
          className="relative mt-20 bg-cover bg-center text-white py-24"
          style={{
            backgroundImage: `url(${nursery})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="container mx-auto relative z-10">
            <div className="w-full mx-auto mb-12">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mx-auto w-4/5 sm:h-72">
                <div className="shadow-lg bg-black bg-opacity-40">
                  <div className="flex items-center justify-center mb-3">
                    <div className="">
                      <img
                        src={greenpepper}
                        alt=""
                        className="h-[145px] w-full"
                      />
                    </div>
                  </div>
                  <h3 className="pl-2 text-xm font-[500] text-slate-300">
                    Green peppers
                  </h3>
                  <p className="pl-2 text-sm text-slate-100">
                    Green peppers are a fresh, crisp, and nutritious addition to
                    any meal. Their vibrant color and mild, flavorful taste
                    enhance dishes, making them both healthy and delicious.
                  </p>
                </div>
                <div className="shadow-lg bg-black bg-opacity-40">
                  <div className="flex items-center justify-center mb-3">
                    <div className="">
                      <img src={nursery} alt="" className="h-[145px] w-full" />
                    </div>
                  </div>
                  <h3 className="pl-2 text-xm font-[500] text-slate-300">
                    Lemon nursery
                  </h3>
                  <p className="pl-2 text-sm text-slate-100">
                    A lemon nursery radiates optimism and growth, cultivating
                    vibrant lemon trees. The air is filled with the invigorating
                    scent of lemon blossoms, promising abundant harvests.
                  </p>
                </div>
                <div className="shadow-lg bg-black bg-opacity-40">
                  <div className="flex items-center justify-center mb-3">
                    <div className="">
                      <img src={tomato} alt="" className="h-[145px] w-full" />
                    </div>
                  </div>
                  <h3 className="pl-2 text-xm font-[500] text-slate-300">
                    Tomatoes
                  </h3>
                  <p className="pl-2 text-sm text-slate-100">
                    Tomatoes are a culinary cornerstone, celebrated for their
                    vibrant red hue and versatile flavor. These luscious fruits
                    enrich countless recipes, from salads to sauces, with their
                    juicy, savory essence.
                  </p>
                </div>
                <div className="shadow-lg bg-black bg-opacity-40">
                  <div className="flex items-center justify-center mb-3">
                    <div className="">
                      <img src={banana} alt="" className="h-[145px] w-full" />
                    </div>
                  </div>
                  <h3 className="pl-2 text-xm font-[500] text-slate-300">
                    Banana plantation
                  </h3>
                  <p className="pl-2 text-sm text-slate-100">
                    A lush expanse of green, where rows of towering banana
                    plants sway in tropical breezes. This thriving ecosystem
                    bears sweet, potassium-rich fruit, embodying agricultural
                    abundance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="gallery">
          <h1
            className="text-center text-xl sm:text-2xl font-[600] my-5"
            style={{ color: "#0e314c" }}
          >
            Gallery
          </h1>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4 sm:w-1/2 mx-auto mb-12">
            <div class="gallery-item shadow-md p-2 h-40">
              <img
                src={image1}
                alt="Image 1"
                class="object-cover h-full w-full"
              />
            </div>
            <div class="gallery-item shadow-md p-2 h-40">
              <img
                src={image2}
                alt="Image 2"
                class="object-cover h-full w-full"
              />
            </div>
            <div class="gallery-item shadow-md p-2 h-40">
              <img
                src={image3}
                alt="Image 3"
                class="object-cover h-full w-full"
              />
            </div>
            <div class="gallery-item shadow-md p-2 h-40">
              <img
                src={image4}
                alt="Image 4"
                class="object-cover h-full w-full"
              />
            </div>
            <div class="gallery-item shadow-md p-2 h-40">
              <img
                src={image5}
                alt="Image 5"
                class="object-cover h-full w-full"
              />
            </div>
            <div class="gallery-item shadow-md p-2 h-40">
              <img
                src={image6}
                alt="Image 6"
                class="object-cover h-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
