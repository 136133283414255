import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.png";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav
      className={`${
        isScrolled ? "bg-white fixed top-0 left-0 w-full shadow-lg" : ""
      }`}
    >
      <div className="max-w-7xl mx-auto px-3 pl-0 sm:px-6 lg:px-6">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-end">
            <img src={logo} alt="logo" className="w-12" />
            <h2
              className="font-semibold text-lg sm:text-2xl"
              style={{ color: "#0e314c" }}
            >
              <span
                className="text-lg sm:text-2xl"
                style={{ color: "#106E39" }}
              >
                Posiye {"  "}
              </span>
              Gardens
            </h2>
          </div>
          <div className="hidden sm:block">
            <ul className="flex space-x-4 text-gray-600 font-semibold">
              <NavItem href="/" text="Home" />
              <NavItem href="/about" text="About us" />
              <NavItem href="tel:+26588590661" text="Contact" />
            </ul>
          </div>
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="block text-gray-600 hover:text-green-700 focus:text-green-700 focus:outline-none"
            >
              <MenuIcon isOpen={isMenuOpen} />
            </button>
          </div>
          <div
            className={`absolute top-16 left-0 ${
              isMenuOpen ? "block" : "hidden"
            } bg-white w-full z-20`}
          >
            <MobileNavItems isOpen={isMenuOpen} />
          </div>
        </div>
      </div>
    </nav>
  );
}

function NavItem({ href, text }) {
  return (
    <li>
      <a href={href} className="block hover:text-green-700">
        {text}
      </a>
    </li>
  );
}

function MenuIcon({ isOpen }) {
  return (
    <>
      <svg
        className={`h-6 w-6 ${isOpen ? "hidden" : "block"}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
      <svg
        className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </>
  );
}

function MobileNavItems({ isOpen }) {
  const navItems = [
    { href: "/", text: "Home" },
    { href: "/about", text: "About us" },
    { href: "tel:+26588590661", text: "Contact" },
  ];

  return (
    <ul className="pl-5 pt-2 pb-4 space-y-1">
      {navItems.map((item, index) => (
        <NavItem key={index} href={item.href} text={item.text} />
      ))}
    </ul>
  );
}

export default Navbar;
