import React from "react";
import { IoLogoInstagram } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { CiFacebook } from "react-icons/ci";

function Footer() {
  return (
    <div id="footer" className="bg-slate-100">
      <div className="text-center">
        <h2
          className="font-semibold text-base sm:text-2xl ml-2 sm:ml-3"
          style={{ color: "#0e314c" }}
        >
          <span className=" text-base sm:text-2xl" style={{ color: "#106E39" }}>
            Posiye{" "}
          </span>
          Gardens
        </h2>
        <p className="text-sm mb-2" style={{ color: "#6084a4" }}>
          Phone: <a href="tel:+26588590661">+265 885 390 661</a>
        </p>
        <p className="text-sm mb-2" style={{ color: "#6084a4" }}>
          P.O Box 2786
        </p>
        <p className="text-sm mb-2" style={{ color: "#6084a4" }}>
          Blantyre
        </p>
      </div>
      <div className="border-t-2 border-dashed mx-auto flex items-center justify-center">
        <p className="py-5 text-xs sm:text-sm" style={{ color: "#6084a4" }}>
          © 2023 Posiye Gardens. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
