import React from "react";
import nursery from "../assets/images/nursery.jpg";
import banana_plantation from "../assets/images/banana_plantation.png";

function About() {
  return (
    <div>
      <div className="relative">
        <div
          className="hero bg-cover bg-center text-white py-24"
          style={{
            backgroundImage: `url(${banana_plantation})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40"></div>

          <div className="container mx-auto  relative z-10">
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              Posiye Gardens
            </h1>
            <p className="text-lg md:text-xl mb-8">
              Cultivating Growth, One Plant at a Time.
            </p>
            <button className="bg-black bg-opacity-40 text-slate-100 font-bold py-2 px-4 rounded-lg">
              Get to know us
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-10 pb-10 px-5 sm:px-10">
        <div>
          <h2
            className="font-[500] text-xl sm:text-2xl sm:w-3/5"
            style={{ color: "#0e314c" }}
          >
            <span style={{ color: "#106E39" }}>What</span> we do
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-full my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            At our tree plantation farm, we are dedicated to fostering a greener
            and healthier planet. With unwavering commitment, we cultivate
            diverse species of trees, ensuring they thrive and flourish. Every
            sapling we nurture represents a step towards a more sustainable
            future. Our farm is a haven of growth, where each tree is
            meticulously cared for, from the moment it takes root until it
            stands tall and proud in the landscape. We invite you to join us in
            this noble endeavor, contributing to the preservation of our
            environment and the well-being of future generations. Together, we
            can make a profound impact, one tree at a time.
          </p>
        </div>
        <div>
          <h2
            className="font-[500] text-xl sm:text-2xl sm:w-3/5"
            style={{ color: "#0e314c" }}
          >
            <span style={{ color: "#106E39" }}>Why</span> Industry-Specific?
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-full my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            Nurseries provide the foundation for successful gardening and
            landscaping endeavors by offering healthy, well-cultivated plants
            that have undergone meticulous care. Whether you're a homeowner
            looking to beautify your surroundings, a farmer in need of robust
            crop seedlings, or a conservationist focused on preserving rare
            species, nurseries offer a diverse array of plant species and expert
            guidance. These establishments are not just greenhouses; they're
            hubs of green life and knowledge, contributing to the vitality of
            landscapes, ecosystems, and communities alike.
          </p>
        </div>
      </div>
      <div>
        <h2
          className="font-[500] text-xl sm:text-2xl sm:w-3/5 ml-10"
          style={{ color: "#0e314c" }}
        >
          <span style={{ color: "#106E39" }}>Why </span> Choose Us?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 pt-10 pb-10 px-5 sm:px-10">
          <div>
            <h2
              className="font-[400] text-xl sm:text-2xl sm:w-3/5"
              style={{ color: "#0e314c" }}
            >
              Quality
            </h2>
            <p
              className="text-[14px] sm:text-sm sm:w-full my-4"
              style={{ color: "#6084a4", lineHeight: 1.8 }}
            >
              Our nursery is dedicated to the art and science of plant
              propagation and cultivation. We have skilled horticulturists who
              meticulously nurture plants from their earliest stages. Our
              professionals create optimal growing conditions, ensuring that
              each plant receives the right amount of light, water, and
              nutrients. This meticulous care results in plants that are not
              just alive but thriving. When customers purchase plants from us,
              they are getting specimens that have the best possible chance of
              survival and success in their new environment. Whether it's a
              delicate flowering shrub or a robust shade tree, the quality of
              our nursery-grown plants contributes to the long-term health and
              beauty of gardens and landscapes.
            </p>
          </div>
          <div>
            <h2
              className="font-[400] text-xl sm:text-2xl sm:w-3/5"
              style={{ color: "#0e314c" }}
            >
              Variety
            </h2>
            <p
              className="text-[14px] sm:text-sm sm:w-full my-4"
              style={{ color: "#6084a4", lineHeight: 1.8 }}
            >
              Our nursery is like botanical treasure troves, offering a vast
              array of plant species and cultivars. This diversity allows
              customers to explore a wide spectrum of options, from the
              tried-and-true favorites to rare and exotic specimens. Whether
              you're looking for plants that attract pollinators, native species
              to support local ecosystems, or colorful blooms to enhance your
              garden's aesthetics, nurseries have you covered. This variety not
              only enriches the visual appeal of landscapes but also empowers
              gardeners and landscapers to create unique and personalized
              environments that suit their tastes and specific needs.
            </p>
          </div>
          <div>
            <h2
              className="font-[400] text-xl sm:text-2xl sm:w-3/5"
              style={{ color: "#0e314c" }}
            >
              Expertise 
            </h2>
            <p
              className="text-[14px] sm:text-sm sm:w-full my-4"
              style={{ color: "#6084a4", lineHeight: 1.8 }}
            >
              One of the invaluable assets of our nursery is the knowledge and
              expertise of the staff. Posiye gardens professionals possess in-depth
              understanding of plant biology, growth habits, and maintenance
              requirements. We can provide customers with valuable advice on
              plant selection, care, and maintenance tailored to their specific
              geographical region and climate. Novice gardeners and landscapers,
              in particular, benefit from this guidance, as it helps them make
              informed decisions and avoid common pitfalls. Whether it's
              selecting the right plants for a shady corner of the garden,
              addressing pest issues, or understanding proper watering
              techniques, the expertise offered by our staff ensures that
              customers can nurture their plants effectively and enjoy
              successful gardening and landscaping experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
